import Header from './Header'
import Footer from './Footer'

export default function Features() {
    return (
        <>
            <Header />
            <Footer />
        </>
    )
}