import Header from "./Header"
import Footer from "./Footer"

export default function Company() {
    return (
        <>
            <Header />
            <Footer />
        </>
    )
}